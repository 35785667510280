const URLS = {
  // general
  CALLBACK: '/callback',
  HACKROOM: '/hackroom',
  HELP: '/help',
  HELP_CATEGORY: '/help/tag/:category',
  HELP_VIDEO: '/help/:videoSlug',
  HOME: '/home',
  IMPORT: '/import',
  LOGOUT: '/logout',
  PROFILE: '/profile',
  PROFILE_TAB: '/profile/:tab',
  ROOT: '/',

  // smartschool
  USE_IN_SMARTSCHOOL: '/use-in-smartschool',

  // public routes
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',

  // results
  CALENDAR_ITEM_RESULTS:
    '/results/program/:programId/calendarItem/:calendarItemId',
  STUDENT_RESULT_PAGE_FOR_LESSON_PROGRAM:
    '/results/calendarItem/:calendarItemId/program/:programId/student/:userId/lesson/:sessionId',
  STUDENT_RESULT_PAGE_FOR_LESSON_TAB_PROGRAM:
    '/results/calendarItem/:calendarItemId/program/:programId/student/:userId/lesson/:sessionId/:tab',

  // classgroup
  CLASSGROUP: '/classgroups',
  CLASSGROUP_DETAIL: '/classgroups/:classGroupId',
  CLASSGROUP_DETAIL_TAB: '/classgroups/:classGroupId/:tab',
  CLASSGROUP_STUDENT_PROFILE: '/classgroups/:classGroupId/students/:studentId/',
  CLASSGROUP_STUDENT_PROFILE_TAB:
    '/classgroups/:classGroupId/students/:studentId/:tab',
  NO_CLASSES: '/no-classes',

  // exercise
  EXERCISE: '/exercises',
  EXERCISE_DETAIL: `/exercises/detail/:exerciseId`, // todo: this is no longer used, definitely needs studioId?
  EXERCISE_NEW: `/exercises/new`,
  EXERCISE_OPEN: `/exercises/open/:userId/:exerciseVersionId/:lessonSessionId`,
  EXERCISE_OVERVIEW: '/exercises',
  EXERCISE_START: `/exercises/start/:studioId/`,
  EXERCISE_START_EXERCISEVERSIONID: `/exercises/start/:studioId/:exerciseVersionId`,
  EXERCISE_START_EXERCISEVERSIONID_LESSONSESSIONID: `/exercises/start/:studioId/:exerciseVersionId/:lessonSessionId`,

  // learn
  LEARN: '/learn',
  LEARN_PROGRAM: '/learn/:programId',
  LEARN_PROGRAM_CHAPTER: '/learn/:programId/:chapterId',
  LEARN_TAB_PROGRAM_CHAPTER: '/learn/:programId/:chapterId/:tab?',
  LESSON_RESULTS:
    '/learn/:programId/:chapterId/:chapterItemId/:lessonId/results',
  OWN_LESSONS_OVERVIEW: '/learn/own-lessons',
  OWN_LESSONS_RESULTS: '/learn/own-lessons/:studioId/results',
  STUDENT_ANSWERS: '/results/:studentId',

  // slideviewer
  SLIDEVIEWER: '/slideviewer',
  SLIDEVIEWER_HACKROOM_LESSON:
    '/slideviewer/hackroom/lesson/:studioId/:viewMode?/:slideId?',
  SLIDEVIEWER_OVERVIEW: '/slideviewer/:studioId',
  SLIDEVIEWER_OVERVIEW_SLIDE: '/slideviewer/:studioId/:viewMode',
  SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID:
    '/slideviewer/:studioId/:viewMode/:sessionId',
  SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID_SLIDEID:
    '/slideviewer/:studioId/:viewMode/:sessionId/:slideId',
  SLIDEVIEWER_OVERVIEW_VIEWMODE: '/slideviewer/:studioId/:viewMode',
  SLIDEVIEWER_OVERVIEW_VIEWMODE_SESSIONID:
    '/slideviewer/:studioId/:viewMode/:sessionId',

  // manage
  MANAGE: '/manage',
  MANAGE_CLASS_DETAILS: '/manage/class/:classId/:filter?',
  MANAGE_CLASS_DETAILS_ALL: '/manage/class/all/:classId/:filter?', // also show archived classes
  MANAGE_ORGANIZATION: '/manage/organization/:organizationId',
  MANAGE_ORGANIZATION_TAB: '/manage/organization/:organizationId/:tab',
  MANAGE_PROGRAM_DETAILS: '/manage/programs/:programId/:filter?',
  MANAGE_PROGRAM_USAGE: '/manage/programs/usage/:programId/:filter?',
  MANAGE_SMARTSCHOOL_TAB: '/manage/smartschool/:tab',
  MANAGE_TAB: '/manage/:tab',
  MANAGE_TEAMS_TAB: '/manage/teams/:tab',
  OVERVIEW: '/manage',

  TOOLS: '/tools',
  TOOLS_COPY_LESSON: '/tools/copy-lesson',
  TOOLS_MOVE_LESSON: '/tools/move-lesson',

  // schooladmin urls
  USER_PROFILE: '/manage/user/:userId',
  USER_PROFILE_VIA_CLASS: '/manage/user/:userId/class/:classId/:className?',

  // impersonate
  IMPERSONATE: '/impersonate',

  CODEFEVER: '/codefever',

  MULTI_SCREEN: '/multiscreen',
};

export const studioUrls = {
  IMPERSONATE: '/impersonate/:id',
};

export const adminTabs = {
  ORGANIZATIONS: 'organizations',
  PROGRAMS: 'programs',
  REPORTS: 'reports',
  TAGS: 'tags',
  DEFAULT: 'organizations',
};

export const chapterItemTabs = {
  DEFAULT: 'lessons',
  LESSONS: 'lessons',
  PROGRESS: 'progress',
};

export const schooladminTabs = {
  CLASSES: 'classes',
  DEFAULT: 'users',
  PROGRAMS: 'programs',
  SMARTSCHOOL: 'smartschool',
  TEAMS: 'teams',
  USERS: 'users',
};

export const organizationTabs = {
  DEFAULT: 'users',
  PERIODS: 'periods',
  PROGRAMS: 'programs',
  REPORTS: 'reports',
  USERS: 'users',
};

export const classDetailTabs = {
  DEFAULT: 'students',
  EXERCISES: 'exercises',
  LESSONS: 'lessons',
  STUDENTS: 'students',
  TESTS: 'tests',
};

export const studentResultTab = {
  DEFAULT: 'responses',
  RESPONSES: 'responses',
  SCRATCH_EXERCISES: 'scratch_exercises',
};

export const userProfileTabs = {
  DEFAULT: 'info',
  EXERCISES: 'exercises',
  INFO: 'info',
  RESULTS: 'results',
};

export const profileTab = {
  ACCOUNT: 'account',
  AVATAR: 'avatar',
  DEFAULT: 'account',
  NOTIFICATIONS: 'notifications',
  PREFERENCES: 'preferences',
  TEAMS: 'teams',
};

export const teamsSyncTabs = {
  STATUS: 'status',
  SYNC: 'synchronize',
};

export const smartschoolSyncTabs = {
  STATUS: 'status',
  SYNC: 'synchronize',
};

export default URLS;
