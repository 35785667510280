import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from '@ftrprf/hooks';

import { useQueryParams } from 'use-query-params';
import { PageHeaderWrapper } from 'components/PageHeaderWrapper';
import useTeacherExportResults from 'hooks/api/plannerService/teacher/mutations/useTeacherExportResults';
import { useTeacherCalendarItemById } from 'hooks/api/plannerService/teacher/queries/useTeacherCalendarItemById';
import { useTeacherStudentsByCalendarItemId } from 'hooks/api/plannerService/teacher/queries/useTeacherStudentsByCalendarItemId';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';
import { UserContext } from 'providers/UserProvider';
import { filterOperation } from 'utils/constants/filter';
import { sortOperation } from 'utils/constants/sort';
import viewModes from 'utils/constants/viewModes';
import { CalendarItemResultsOverview } from './CalendarItemResultsOverview';
import { searchParamsHaveDefaultValues } from './partials/searchParamsHaveDefaultValues';
import { ExportResultButton } from './partials/ExportResultButton';

export function CalendarItemResultsOverviewContainer() {
  const MAX_ITEMS = 500;

  const [searchParams, setSearchParams] = useQueryParams();
  const t = useFormatMessage();

  const {
    id: userId,
    isSmartschoolUser,
    isTeamsUser,
  } = useContext(UserContext);
  const [isSendToScoreButtonDisabled, setIsSendToScoreButtonDisabled] =
    useState(false);
  const { calendarItemId } = useParams();
  const [filterStudents, setFilterStudents] = useState('');
  const [activeViewMode, setActiveViewMode] = useState(viewModes.CLASSICAL);
  const [filterClassGroup, setFilterClassGroup] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(MAX_ITEMS);
  const [lastEventStatus, setLastEventStatus] = useState();

  const debouncedNameFilter = useDebounce(filterStudents, 300);

  const { data: calendarItemData, refetch } =
    useTeacherStudentsByCalendarItemId({
      calendarItemId,
      filter: [
        Boolean(filterStudents) && {
          key: 'fullname',
          operation: filterOperation.LIKE,
          value: debouncedNameFilter,
        },
        Boolean(filterClassGroup.length) && {
          key: 'classGroupId',
          operation: filterOperation.EQUAL,
          value: filterClassGroup,
        },
      ].filter(Boolean),
      page: page - 1,
      size: itemsPerPage,
      sort: { fullname: sortOperation.ASC },
    });

  const students = calendarItemData?.content;
  const totalPages = calendarItemData?.pages;
  const totalItems = calendarItemData?.total;

  const { data: calendarItem, refetch: refetchCalendarItem } =
    useTeacherCalendarItemById(calendarItemId);

  const pageTitle = calendarItem?.chapterItem
    ? [
        calendarItem?.chapterItem?.name,
        calendarItem?.chapterItem?.lessons[0]?.title,
      ].join(': ') || ''
    : calendarItem?.lessonContentTitle || '';
  useTitle(`${t('content.results')} ${pageTitle}`);

  const breadcrumbsLinks = [
    {
      name: pageTitle,
    },
  ];

  const isOwnCalendarItemWithStudents =
    calendarItem?.userId === userId && // one can only send results for their own items
    Boolean(students?.length); // without students, no results really
  const canSendResultsForTeams =
    isTeamsUser && calendarItem?.externalAssignmentId; // without an externalAssignmentId we don't know where to put the points
  const teacherIsAllowedToSendResults =
    isOwnCalendarItemWithStudents &&
    (canSendResultsForTeams || isSmartschoolUser);

  useEffect(() => {
    void refetch();
  }, [refetch, debouncedNameFilter, calendarItemId]);

  useEffect(() => {
    if (
      !searchParamsHaveDefaultValues(
        filterClassGroup,
        debouncedNameFilter,
        activeViewMode,
      )
    ) {
      // this only gets invoked if there are non-default search params
      setSearchParams({
        classgroups: filterClassGroup,
        fullname: debouncedNameFilter,
        page,
        viewmode: activeViewMode,
      });
    } else {
      // this ensures the search params are removed from the url if there are only default values
      setSearchParams({}, 'replace');
    }
  }, [
    activeViewMode,
    debouncedNameFilter,
    filterClassGroup,
    page,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    const eventStatuses = calendarItem?.eventStatuses;
    if (!!calendarItem && Boolean(eventStatuses?.length)) {
      const mostRecentStatus = eventStatuses[0];

      setLastEventStatus(mostRecentStatus);
    }
  }, [calendarItem]);

  const { mutateAsync: exportResults } = useTeacherExportResults();
  const exportResultsHandler = () => {
    setIsSendToScoreButtonDisabled(true);
    void exportResults({
      calendarItemId: calendarItem.id,
      viewMode: activeViewMode,
    }).finally(() => {
      // the setTimeout is necessary because if we do it too quickly, then we don't get the last eventStatus.
      // backend be sloooooow
      setTimeout(() => {
        void refetchCalendarItem().then(() => {
          setIsSendToScoreButtonDisabled(false);
        });
      }, 1000);
    });
  };

  return (
    <>
      <PageHeaderWrapper
        breadcrumbs={breadcrumbsLinks}
        subtitle={t('results-overview.title.results')}
        title={pageTitle}
      >
        {teacherIsAllowedToSendResults && (
          <ExportResultButton
            exportResultsHandler={exportResultsHandler}
            isSendToScoreButtonDisabled={isSendToScoreButtonDisabled}
            lastEventStatus={lastEventStatus}
          />
        )}
      </PageHeaderWrapper>
      <CalendarItemResultsOverview
        activeViewMode={activeViewMode}
        calendarItem={calendarItem}
        classGroups={calendarItem?.classGroups}
        currentPage={page}
        filterClassGroup={filterClassGroup}
        filterStudents={filterStudents}
        isLoading={!students || !calendarItem}
        itemsPerPage={itemsPerPage}
        searchParams={searchParams}
        setActiveViewMode={setActiveViewMode}
        setFilter={setFilterStudents}
        setFilterClassGroup={setFilterClassGroup}
        setItemsPerPage={setItemsPerPage}
        setPage={setPage}
        students={students}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </>
  );
}
