/* eslint-disable camelcase */
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { IDPS } from 'utils/constants/idp';
import URLS from 'utils/constants/urls';
import getGenericSessionId from 'utils/getGenericSessionId';
import { jwtDecode } from 'jwt-decode';
import { UserContext } from 'providers/UserProvider';

export default function CodeFeverLanding({ account, inProgress }) {
  // localhost:5000/codefever?session_token=6b85366b4ecd24fc488451bb83aefa88&target_link_uri=3700/PROJECTION
  // of
  // ?id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6IkxzTEhMTl8tbmUwandFWkRpLThYY0t4ZzB5b0ZkTHFtd3dLb3QwUUZxdXciLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL2Z0cnByZnRzdGIyYy5iMmNsb2dpbi5jb20vYTc2MTI3ZTctZjBhOS00ZTVjLWIyYjgtOGMzYjU2NDc3ZGI2L3YyLjAvIiwic3ViIjoiYWUxZmVjZTYtM2FmYS00ZDQwLWIyZDgtOGY4NTc5NjhkNzRmIiwiYXVkIjoiMjNhYWNjOGMtODQyZi00ODJhLTlmOWYtNjRjMjViZWM1YmJlIiwiZXhwIjoxNjk0NjAxNTI2LCJhY3IiOiJiMmNfMWFfNGFfM18yXzFfcmVseWluZ3BhcnR5X3VzZXJuYW1lX3NpZ25pbiIsImlhdCI6MTY5NDU5NzkyNiwiYXV0aF90aW1lIjoxNjk0NTk3OTI2LCJpZHBfdXNlcmlkIjoiMTI5IiwiZmlyc3RuYW1lIjoiRmllbiIsImxhc3RuYW1lIjoiU3ByaWV0IiwiZGlzcGxheW5hbWUiOiJGaWVuIFNwcmlldCIsImlkcCI6IkNPREVGRVZFUiIsImlkcF9wbGF0Zm9ybSI6IkNvZGVGZXZlck9yZ2FuaXphdGlvbiIsInVzZXJuYW1lIjoiZmllbnNwcmlldCIsImxhbmd1YWdlIjoibmwiLCJsYXVuY2hfdXJpX3JlZGlyZWN0X3VybCI6IjIyMDIvQ0xBU1NJQ0FMIiwib3JnYW5pemF0aW9uaWQiOiIyNTQiLCJyb2xlcyI6WyJGVFJQUkZfU0NIT09MVEVBQ0hFUiJdLCJuYmYiOjE2OTQ1OTc5MjZ9.fzBM8y-POzvbYUI5dGF_8C9TgmA6TbY8zLWCqaL4dnDNAwEhCLNZCmLXd3qib7mH5N9sIX7DrAcltlWUfkOj0k7k6lt-uBxyiyg-9AmTij2HtFcGmZknqVT89wC6ynNnkHRBOyf-QQU_D0wnnweNqvCNvdpKeRq3aBLuGKUsYhpWWR4l4v97NcVCjvPlHyQcONQzSBmdU1NdfQuQNfKNKDEU18EARFCRZlqynDMy3tkDbQ1A4Gn_9WtiLk475hrsMf_8rlGiqgwYxl36M-dr0pCU0MtuY-72BxNGXj6v-xyU48sNHmaLMPG_gIFYrk2ZGLN6ThBxNz8oAm2uAyJxSQ

  const [searchParams] = useSearchParams();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [alert] = useState(
    'We bekijken nog alles, blijf even zitten op je stoel',
  );
  const [tries, setTries] = useState(0);
  const { initialToken } = useContext(UserContext);
  const id_token = searchParams.get('id_token')
    ? jwtDecode(searchParams.get('id_token'))
    : undefined;
  const session_token = searchParams.get('session_token');
  const target_link_uri = searchParams.get('target_link_uri');

  function redirectToCodeFeverAuth() {
    if (session_token && target_link_uri) {
      window.location = `${process.env.REACT_APP_CODEFEVER_SERVICE_INIT_URL}?session_token=${session_token}&target_link_uri=${target_link_uri}&redirect_uri=${process.env.REACT_APP_AD_REDIRECT_LINK}`;
    } else {
      console.error('Codefever page without the right params?');
    }
  }

  function redirectToLesson(target_link_uri) {
    if (!target_link_uri) {
      console.error('Codefever page without the right params?');
    } else {
      const [studioId, viewMode] = target_link_uri.split('/');

      navigate(
        generatePath(URLS.SLIDEVIEWER_OVERVIEW_SLIDE_SESSIONID, {
          sessionId: getGenericSessionId(studioId),
          studioId,
          viewMode,
        }),
      );
    }
  }

  function handleCodeFeverUserRedirect() {
    // http://localhost:5000/codefever?id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6IkxzTEhMTl8tbmUwandFWkRpLThYY0t4ZzB5b0ZkTHFtd3dLb3QwUUZxdXciLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE3MjgwNTI1OTQsIm5iZiI6MTcyODA0ODk5NCwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly9mdHJwcmZ0c3RiMmMuYjJjbG9naW4uY29tL2E3NjEyN2U3LWYwYTktNGU1Yy1iMmI4LThjM2I1NjQ3N2RiNi92Mi4wLyIsInN1YiI6Ijc5OTU4ZTU1LTcxZDktNDRmZC1hNzQ2LWI1ZWM4N2Q4NTZjNSIsImF1ZCI6IjIzYWFjYzhjLTg0MmYtNDgyYS05ZjlmLTY0YzI1YmVjNWJiZSIsImFjciI6ImIyY18xYV80YV8zXzJfMV9yZWx5aW5ncGFydHlfdXNlcm5hbWVfc2lnbmluIiwiaWF0IjoxNzI4MDQ4OTk0LCJhdXRoX3RpbWUiOjE3MjgwNDg5OTQsInVzZXJuYW1lIjoidGVhY2hlciIsImxhbmd1YWdlIjoibmwiLCJvcmdhbml6YXRpb25pZCI6IjEiLCJpZHAiOiJGVFJQUkYiLCJkaXNwbGF5bmFtZSI6IlRlYWNoZXIgRGUgR29lZGUiLCJmaXJzdG5hbWUiOiJUZWFjaGVyIiwibGFzdG5hbWUiOiJEZSBHb2VkZW4iLCJyb2xlcyI6WyJGVFJQUkZfU0NIT09MVEVBQ0hFUiJdfQ.pt-NzpMwu6tjGQ-W7_t3DzaNv2MLRMPMXXP90qLxHQvTN0Gn3qmCs1j2157UHgdj-H1czZdbQ6oPkxiJIKc1zYQG_YjYdScMgKFwojlbLZeexcgGWwSa4uenhLHYg6ZWxEgnds6OrqKIetRqGCHvO4mmUjwFN6w_8UeKCls2GnhBzTLavog49FWYcrJfFOZURkFlb2Ned_dGq7D0Tozj1Uh4AZyoO6UDHyJ6Wnm_NvkLgnSvwS9rmvx5S2Kn2PsJTeMXR3XI3RT4hDYn6u6L8KtlEGSOtaKqhz5hqCijP0a3VttuFBswR1UxCAszQ1mE2-Q4KnD_3vrgHkBX_ahg5g
    console.log('user, logout, redirect');
    void instance.logoutPopup();
  }

  function handleNoUserFoundRedirect() {
    if (id_token) {
      console.log('user, token, login redirect');
      instance.loginRedirect().catch(() => instance.handleRedirectPromise());
    } else {
      console.log('no user, redirect');
      redirectToCodeFeverAuth();
    }
  }

  function openLesson() {
    const { launch_uri_redirect_url } = id_token || {};

    redirectToLesson(target_link_uri || launch_uri_redirect_url);
  }

  function handleAccountRedirect() {
    if (account.idTokenClaims?.idp === IDPS.CODEFEVER) {
      /*
        CODEFEVER USER FOUND
        Let's check if their session is still valid
      */
      if (!initialToken && tries < 10) {
        // initialToken is undefined if you check it too fast, so delay by 200 ms and then try again
        setTries(tries + 1);
        setTimeout(() => handleAccountRedirect, 200);
      } else {
        // check if session token is still valid => if it is: go ahead,
        // else: do logout & redirect
        const decodedToken = jwtDecode(initialToken);
        const currentSessionToken = decodedToken?.sessionToken;
        if (currentSessionToken !== session_token) {
          handleCodeFeverUserRedirect();
        } else {
          // codefever login with valid session token: gogogo!
          openLesson();
        }
      }
    } else {
      handleCodeFeverUserRedirect();
    }
  }

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (!account) handleNoUserFoundRedirect();

      if (account) handleAccountRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, inProgress]);

  return (
    <div className="p-4 text-center">
      {alert ? <p>{alert}</p> : <p>We laden je les...</p>}
    </div>
  );
}
