import { InteractionStatus } from '@azure/msal-browser';
import queryString from 'query-string';

import URLS from 'utils/constants/urls';

import { clearCacheAndCookies } from 'utils/clearCacheAndCookies';

export function handleRedirectAndLocalStorage(account, inProgress, instance) {
  if (
    !account &&
    inProgress === InteractionStatus.None &&
    !window.location.pathname.startsWith(URLS.PRIVACY_POLICY) &&
    !window.location.pathname.startsWith(URLS.TERMS_OF_SERVICE) &&
    !window.location.pathname.startsWith(URLS.CODEFEVER)
  ) {
    // clear local storage the hard way
    clearCacheAndCookies();

    const parsed = queryString.parse(location.search);
    const loginRedirectRequest = {};

    /*
      If we get the subdomain queryparameter from Smartschool,
      we should add a couple extra queryparam to the login request.
      This will cause the login flow to auto click the smartschool button
      and redirect to the correct smartschool domain so
      the user doesn't need to fill in its subdomain
    */
    if (parsed.subdomain) {
      loginRedirectRequest.extraQueryParameters = {
        domain_hint: 'smartschool',
        platform: parsed.subdomain,
      };
    }

    instance
      .loginRedirect(loginRedirectRequest)
      .catch(() => instance.handleRedirectPromise());
  }
}
