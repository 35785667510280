export const dutch = {
  // Global
  'dropdown.no_results': "Geen opties gevonden",
  'global.account.avatar': 'Avatar',
  'global.account.avatar.save': 'Avatar opslaan',
  'global.account.info': 'Accountgegevens',
  'global.actions': 'Acties',
  'global.activate': 'Activeren',
  'global.all': 'Alles',
  'global.archive': 'Archief',
  'global.archived': 'Gearchiveerd',
  'global.at': 'om',
  'global.buypackages': "Koop extra lespakketten",
  'global.calendar': 'Kalender',
  'global.cancel': 'Annuleren',
  'global.class': 'Klas',
  'global.close': 'Sluiten',
  "global.color": "Kleur",
  'global.configure_users': 'Gebruikers configureren',
  'global.confirm': "Aanmaken",
  'global.content': 'Inhoud',
  'global.copyToClipboard': 'Tekst kopiëren',
  'global.deactivate': 'Deactiveren',
  'global.default.via.profile.part1': 'Je kan de standaardwaarde hiervan instellen in ',
  'global.default.via.profile.part2': 'je profiel.',
  'global.delete': 'Verwijder',
  'global.download': 'Download',
  'global.drag_and_drop_or_browse.excel': "Sleep een excel-bestand, of zoek in uw bestanden",
  'global.drag_and_drop_or_browse.image': "Sleep een foto, of zoek in uw bestanden",
  'global.edit': 'Bewerk',
  'global.edit_user': 'Bewerk gebruiker',
  'global.edit_password': 'Bewerk wachtwoord',
  'global.email': 'E-mail',
  'global.empty': 'Het lijkt erop dat er niets te vinden is',
  'global.empty.adjust_filter': 'Pas uw filter aan om resultaten te zien.',
  'global.empty-lesson': 'Lege les',
  'global.error': "Oeps! Er is iets misgegaan.",
  'global.error.message': 'We weten niet precies wat er fout ging.  Probeer het binnen een paar minuten nog eens.  Als het blijft fout lopen, neem dan contact op met support@ftrprf.be.',
  'global.example': "Voorbeeld",
  'global.export': 'Exporteer',
  'global.file.delete': "Bestand verwijderen",
  'global.general.info': 'Algemene info',
  'global.given_by': 'Gegeven door',
  'global.go_back': 'Ga terug',
  'global.hack_room': 'Hack Room',
  'global.hide': 'Verberg',
  'global.hourAbbreviation': 'u',
  'global.idp': 'Login',
  'global.image.add': "Foto toevoegen",
  'global.image.delete': "Foto verwijderen",
  'global.image.update': "Foto aanpassen",
  'global.impersonate': 'Impersonate',
  'global.import': 'Importeren',
  'global.indicator': 'indicator',
  'global.items.per.page': 'Items per pagina',
  'global.link.classgroups': "Klas toevoegen",
  'global.link.tags': "Label toevoegen",
  'global.link.programs': "Programma toevoegen",
  'global.loading': 'Laden...',
  'global.login.with.microsoft': 'Inloggen met Microsoft',
  'global.manual': 'Handleiding',
  'global.name': "Naam",
  'global.next': "Volgende",
  'global.none': 'Geen',
  'global.no_results': 'Geen resultaten gevonden',
  'global.not.planned': 'Niet ingepland',
  'global.on': 'op',
  'global.only_word_characters': 'Een domeinvoorvoegsel mag enkel letters, cijfers en een koppelteken bevatten.',
  'global.oops': 'Oeps',
  'global.open': 'Open',
  'global.planned': 'Ingepland',
  'global.presentation': 'Presentatie',
  'global.previous': 'Vorige',
  'global.program': 'Programma',
  'global.redirecting': 'Doorsturen...',
  'global.reload': 'Herladen',
  'global.remove': 'Verwijderen',
  'global.remove.classgroup': 'Klas verwijderen',
  'global.role': 'Rol',
  'global.save.changes': 'Wijzigingen opslaan',
  'global.search.by.name': 'Zoek op naam',
  'global.selected': 'Geselecteerd',
  'global.show': 'Toon',
  'global.showErrors': 'Toon fouten',
  'global.showMore': 'Toon meer',
  'global.smartschool': 'Smartschool',
  'global.start': 'Start',
  'global.status': 'Status',
  'global.teams': 'Teams',
  'global.to': 'Naar',
  'global.today': 'Vandaag',
  'global.tomorrow': 'Morgen',
  'global.unimpersonate': 'Stop impersonaten',
  'global.unknown': 'Onbekend',
  'global.usage': 'Gebruik',
  'global.userId': 'Gebruikers Id',
  "global.issuer": "Uitgever",
  'global.viewMode': 'Weergavemodus',
  'global.weight': "Gewicht / Belangrijkheid",
  'global.yesterday': 'Gisteren',

  // calendar
  'calendar.show': "Toon kalender",

  // validation
  'global.invalid-values': 'Ongeldige waarden',
  'global.max-length-255': 'Dit veld mag maximaal 255 tekens bevatten',
  'global.min-length-8': 'Dit veld moet minimaal 8 tekens bevatten',
  'global.min-length-12': 'Dit veld moet minimaal 12 tekens bevatten',
  'global.password': 'wachtwoord',
  'global.password-pattern': 'Het wachtwoord moet minstens 8 tekens, een hoofdletter, een kleine letter en een cijfer bevatten',
  'global.present': 'Presenteer',
  'global.progress': 'Vooruitgang',
  'global.required-field': 'Verplicht veld',
  'global.required-number': 'Dit veld moet een getal bevatten',
  'global.reset-password': 'Wijzig wachtwoord',
  'global.reset-password.confirm': 'Wijzig',
  'global.save': 'Sla op',
  'global.save-changes': 'Sla wijzigingen op',
  'global.select.all': 'Alles selecteren',
  'global.select.none': 'Alles wissen',
  'global.snooze': 'Stel uit',
  'global.taskInfoStatus.all': 'Alles',
  'global.taskInfoStatus.completed': 'Voltooid',
  'global.taskInfoStatus.failed': 'Mislukt',
  'global.taskInfoStatus.in_progress': 'In uitvoering',
  'global.total-amount': 'totaal aantal',
  'global.try-again': 'Probeer opnieuw',
  'global.uuid-length': 'Een ID moet exact 36 karakters bevatten',
  'global.uuid-valid': 'Dit is een ongeldige id.  Een ID mag enkel cijfers, letters, en koppeltekens bevatten.',
  'global.unlink.classgroup': 'Klas ontkoppelen',
  'global.unlink.program': 'Programma verwijderen',
  'global.unlink.tags': 'Label ontkoppelen',
  'global.update': 'Bijwerken',
  'global.username': 'Gebruikersnaam',
  'global.view': 'Bekijk',
  'global.work_in_progress': 'Nog onder constructie',


  // accessibility-related stuff
  'global.keyboard.skiplink': 'Ga naar {destination}',
  'global.sr.about': 'over',

  // language
  'global.LANGUAGE.singular': 'Taal',
  'global.LANGUAGE.select': 'Selecteer taal',

  // success
  'global.create.success': '{what} succesvol aangemaakt',
  'global.add.success': '{what} succesvol toegevoegd',
  'global.activate.success': '{what} succesvol geactiveerd',
  'global.deactivate.success': '{what} succesvol gedeactiveerd',
  'global.delete.success': '{what} succesvol verwijderd',
  'global.disable.success': '{what} succesvol gedeactiveerd',
  'global.enable.success': '{what} succesvol geactiveerd',
  'global.update.success': '{what} succesvol geüpdatet',
  'global.archive.success': '{what} succesvol gearchiveerd',
  'global.remove.success': '{what} succesvol verwijderd',
  'global.post.success': 'succesvol {what} verzonden',
  'global.move.success': 'succesvol {what} verplaatst',


  // error
  'global.activate.error': 'Er liep iets fout bij het activeren van {what}',
  'global.add.error': 'Er liep iets fout bij het toevoegen van {what}',
  'global.archive.error': 'Er liep iets fout bij het archiveren van {what}',
  'global.create.error': 'Er liep iets fout bij het aanmaken van {what}',
  'global.deactivate.error': 'Er liep iets fout bij het deactiveren van {what}',
  'global.delete.error': 'Er liep iets fout bij het verwijderen van {what}',
  'global.disable.error': 'Er liep iets fout bij het deactiveren van {what}',
  'global.enable.error': 'Er liep iets fout bij het activeren van {what}',
  'global.get.error': 'Er liep iets fout bij het ophalen van {what}',
  'global.move.error': 'Er liep iets fout bij het verplaatsen van {what}',
  'global.not_found.error': "{what} kon niet gevonden worden",
  'global.post.error': 'Er liep iets fout bij het verzenden van {what}',
  'global.remove.error': 'Er liep iets fout bij het verwijderen van {what}',
  'global.update.error': 'Er liep iets fout bij het updaten van {what}',

  // generic messages
  "global.add.what": "{what} toevoegen",
  "global.create.what": "{what} aanmaken",
  "global.delete.what": "{what} verwijderen",
  'global.delete.what.confirm': "Weet je zeker dat je deze {what} wilt verwijderen?",
  'global.empty.search': "Er zijn geen {what} gevonden die voldoen aan dit zoekcriterium.",
  "global.update.what": "{what} aanpassen",
  "global.link.what": "{what} koppelen",
  "global.unlink.what": "{what} ontkoppelen",
  "global.manage.what": "{what} beheren",
  'global.empty.link': 'Op dit moment zijn er geen {what} gekoppeld aan {thing}.',

  // warning
  'global.no.users.warning': 'Geen gebruikers beschikbaar voor gepersonaliseerde testen',

  // global articles
  'global.THE': 'de',
  'global.THEH': 'het',
  'global.THE.plural': 'de',

  // global student
  'global.LESSON.plural': 'Lessen',
  'global.LESSON.singular': 'Les',
  'global.LESSON.view': 'Bekijk les',
  'global.lesson.new': 'Nieuwe les aanmaken',

  // global classgroup
  'global.CLASSGROUP.id': 'Klas ID',
  'global.CLASSGROUP.name': 'Klasnaam',
  'global.CLASSGROUP.plural': 'klasgroepen',
  'global.CLASSGROUP.singular': 'klasgroep',

  // global exam
  'global.EXAM.plural': 'Examens',
  'global.EXAM.singular': 'Examen',
  'global.EXAM.view': 'Bekijk examen',

  // global role
  'global.FTRPRF_SCHOOLSTUDENT.singular': 'Student',
  'global.FTRPRF_SCHOOLTEACHER.singular': 'Leerkracht',
  'global.FTRPRF_SCHOOLADMIN.singular': 'Schoolbeheerder',
  'global.FTRPRF_ADMIN.singular': 'Beheerder',
  'global.SCHOOLADMIN.singular': "Schoolbeheerder",
  'global.SCHOOLADMIN.plural': "Schoolbeheerders",

  // global enabled
  'global.enabled.true': 'Actieve gebruikers',
  'global.enabled': 'Geactiveerd',
  'global.disabled': 'Gedeactiveerd',
  'global.enabled.false': 'Inactieve gebruikers',

  // global student
  'global.STUDENT.plural': 'Leerlingen',
  'global.STUDENT.singular': 'Leerling',
  'global.STUDENT.view': 'Bekijk leerling',

  // global class
  'global.CLASS.plural': 'Klassen',
  'global.CLASS.singular': 'Klas',
  'global.CLASS.view': 'Bekijk klas',

  // global result
  'global.RESULT.plural': 'Resultaten',
  'global.RESULT.singular': 'Resultaat',
  'global.RESULT.view': 'Bekijk resultaat',

  // global teacher
  'global.TEACHER.plural': 'Leerkrachten',
  'global.TEACHER.singular': 'Leerkracht',
  'global.TEACHER.view': 'Bekijk leerkracht',

  // global user
  'global.USER.plural': 'Gebruikers',
  'global.USER.singular': 'Gebruiker',
  'global.USER.view': 'Bekijk gebruiker',

  // global school
  'global.SCHOOL.plural': 'Scholen',
  'global.SCHOOL.singular': 'School',
  'global.SCHOOL.view': 'Bekijk school',

  // global program
  'global.PROGRAM.plural': "Programma's",
  'global.PROGRAM.singular': 'Programma',
  'global.PROGRAM.view': 'Bekijk programma',
  'global.PROGRAM.add': 'Voeg programma toe',

  // global exercise
  'global.EXERCISE.plural': 'Oefeningen',
  'global.EXERCISE.singular': 'Oefening',
  'global.EXERCISE.view': 'Bekijk oefening',

  // global exercise
  'global.SCRATCH_EXERCISE.plural': 'Scratchoefeningen',
  'global.SCRATCH_EXERCISE.singular': 'Scratchoefening',
  'global.SCRATCH_EXERCISE.view': 'Bekijk Scratchoefening',

  // global test
  'global.TEST.plural': 'Toetsen',
  'global.TEST.singular': 'Toets',
  'global.TEST.view': 'Bekijk toets',

  // global antwoord
  'global.RESPONSE.plural': 'Antwoorden',
  'global.RESPONSE.singular': 'Antwoord',
  'global.RESPONSE.view': 'Bekijk antwoorden',

  // global organization
  'global.ORGANIZATION.plural': 'Organisaties',
  'global.ORGANIZATION.singular': 'Organisatie',
  'global.ORGANIZATION.view': 'Bekijk organisatie',

  // global organization
  'global.FILE.plural': 'Bestanden',
  'global.FILE.singular': 'Bestand',
  'global.FILE.view': 'Bekijk bestand',

  // global calendaritems
  'global.CALENDARITEM.plural': 'Agendapunten',
  'global.CALENDARITEM.singular': 'Agendapunt',
  'global.CALENDARITEM.view': 'Bekijk agendapunt',

  // global chapter
  'global.CHAPTER.plural': 'Hoofdstukken',
  'global.CHAPTER.singular': 'Hoofdstuk',
  'global.CHAPTER.view': 'Bekijk hoofdstuk',

  // global chapteritem
  'global.CHAPTERITEM.plural': 'Hoofdstukonderdelen',
  'global.CHAPTERITEM.singular': 'Hoofdstukonderdeel',
  'global.CHAPTERITEM.view': 'Bekijk Hoofdstukonderdeel',

  // global notification
  "global.NOTIFICATION.plural": "Aankondigingen",
  "global.NOTIFICATION.singular": "Aankondiging",
  "global.NOTIFICATION.view": "Bekijk aankondiging",

  // global slide
  'global.SLIDE.plural': 'Slides',
  'global.SLIDE.singular': 'Slide',
  'global.SLIDE.view': 'Bekijk slide',

  // global answer
  'global.ANSWER.plural': 'Antwoorden',
  'global.ANSWER.singular': 'Antwoord',
  'global.ANSWER.view': 'Bekijk antwoord',

  // global content
  'global.CONTENT.singular': 'Content',
  'global.CONTENT.view': 'Bekijk content',

  // global feedback
  "global.FEEDBACK": "Feedback",
  "global.FEEDBACK.view": "Bekijk feedback",

  // global score
  "global.SCORE.plural": "Scores",
  "global.SCORE.singular": "Score",

  // global learn
  'global.LEARN': 'Leer',

  // global personality
  'global.personality.test.plural': "Persoonlijkheidstesten",

  // global validation
  "global.validation.unique.what": "{what} moet uniek zijn.",

  // column headers
  'global.table-header.errorMessages': 'Foutmeldingen',
  'global.table-header.imported.classes': 'Geïmporteerde klassen',
  'global.table-header.imported.users': 'Geïmporteerde gebruikers',
  'global.table-header.started_on': 'Gestart op',
  'global.table-header.status': 'Status',

  // assessment status
  "assessment.status.created": "Aangevraagd",
  "assessment.status.finished": "Voltooid",
  "assessment.status.started": "Begonnen",
  "assessment.status.not_send": "Niet verzonden",

  // ChapterItem Report Table
  'chapterItemReport.dialog.title.questions': 'Aantal beantwoorde vragen per kalenderitem',
  'chapterItemReport.dialog.title.score': 'Scores per kalenderitem',
  'chapterItemReport.filter.score': 'Scores tonen',
  'chapterItemReport.filter.questions': 'Aantal beantwoorde vragen tonen',
  'chapterItemReport.no-results-found': 'Er werden geen resultaten gevonden voor deze les',
  'chapterItemReport.tooltip.multiple_questions': 'Er zijn meerdere kalenderitems, klik voor meer informatie',
  'chapterItemReport.tooltip.multiple_no_grade_classical': 'Klassikaal op {startTime}',
  'chapterItemReport.tooltip.multiple_no_grade_selfstudy': 'Zelfstudie op {startTime}',
  'chapterItemReport.tooltip.to_grade': '{amountToGrade} open vragen te verbeteren.',


  // import dialog
  "import.dialog.submission.error": "Er waren problemen bij het importeren.",
  'import.dialog.description': 'Enkel excel bestanden (met als extensie: .xls and .xlsx) zullen geaccepteerd worden.',
  'import.dialog.file.invalid': 'Ongeldig bestand',
  'import.dialog.file.unsupported': 'Bestandstype wordt niet ondersteund',
  'import.dialog.file.valid': 'Geldig bestand',
  'import.dialog.title': 'Importeer studenten en klassen',
  'import.excel': "Excel importeren",
  'import.excel.description': 'Hier kan je gemakkelijk studenten en/of leerkrachten importeren (die toegewezen zijn aan een klas) uit een Excel bestand, door de template te downloaden en deze in te vullen. Upload na het invullen het bestand door op de knop hieronder te klikken.',
  'import.excel.documentation': 'Bekijk template documentatie',
  'import.excel.download': 'Download template',
  'import.excel.max': 'Opgelet: er is een maximum van 2000 gebruikers / import.',
  'import.task': 'de info',

  // viewModes
  'view-modes.projection': 'Projectie',
  'view-modes.selfstudy': 'Thuisversie',
  'view-modes.classical': 'Klasversie',

  // Header
  'header.hello': 'Hallo, ',
  'header.help': 'Hulp',
  'header.help_videos': "Hulpvideo's",
  'header.help_videos.tag': 'Hulpvideo\'s in de categorie {category}',
  'header.logout': 'Uitloggen',
  'header.manage_admin': 'Beheer admin',
  'header.manage_school': 'Beheer school',
  'header.navigation.classes': 'Klassen',
  'header.navigation.exams': 'Test',
  'header.navigation.home': 'Home',
  'header.navigation.manage': 'Beheer',
  'header.navigation.profile': 'Profiel',
  'header.navigation.students': 'Leerlingen',
  'header.navigation.studio': 'Studio',
  'header.navigation.tools': 'Gereedschap',
  'header.no.active.period.schooladmin': 'Er is momenteel geen actieve periode.  Gelieve contact op te nemen met support@ftrprf.be.',
  'header.no.active.period.studentAndTeacher': 'Er is momenteel geen actieve periode.  Gelieve contact op te nemen met uw schooladmin.',
  'header.old_manage_school': 'Oud beheer paneel',
  'header.profile': 'Profiel',
  'header.uiSettings': 'Gebruikersvoorkeuren',

  // Calendar
  'calendar.item.view_results': 'Bekijk resultaten',
  'calendar.modal.closeAfterEndTime': 'Sluiten na eindtijd',
  'calendar.modal.dateEvent': 'Datum van de les',
  'calendar.modal.dateNowValidate': 'De les kan niet in het verleden plaatsvinden',
  'calendar.modal.editLessonContent': 'Wil je de les aanpassen?',
  'calendar.modal.endHour': 'Einduur',
  'calendar.modal.endMinute': 'Eindminuut',
  'calendar.modal.endTime': 'Eindtijd',
  'calendar.modal.extended': 'Toon werkdag',
  'calendar.modal.lesson': 'Versie selecteren',
  'calendar.modal.normal': 'Toon volledige dag',
  'calendar.modal.selectChapter': 'Hoofdstuk selecteren',
  'calendar.modal.selectChapterItem': 'Les selecteren',
  'calendar.modal.selectClass': "Klas selecteren",
  'calendar.modal.selectProgram': 'Programma selecteren',
  'calendar.modal.selectViewMode': 'Weergavemodus selecteren',
  'calendar.modal.startHour': 'Startuur',
  'calendar.modal.startMinute': 'Startminuut',
  'calendar.modal.startTime': 'Starttijd',
  'calendar.modal.startTime<endTime': 'De eindtijd kan niet vroeger zijn dan de starttijd',
  'calendar.modal.teams.assignmentDescription': 'Welke beschrijving wil je voor deze opdracht? (optioneel)',
  'calendar.modal.teams.assignmentTitle': 'Wat moet de titel zijn voor deze opdracht?',
  'calendar.modal.teams.dateInThePast': 'De startdatum die je koos voor dit kalender item ligt in het verleden.  Teams staat helaas niet toe om items in het verleden aan te maken.  Je zal je item dus wel zien in FTRPRF, maar niet in Teams.',
  'calendar.modal.teams.resourceDisplayName': 'Wat is de resourceDisplayName',
  'calendar.modal.title': 'Les inplannen',
  'calendar.modal.titleAddTittle': 'Plan een les in',
  'calendar.modal.week': 'Toon volledige week',
  'calendar.modal.workweek': 'Toon werkweek',
  'calendar.no_classes': 'Geen klassen',
  'calendar.no_teachers': 'Geen leerkrachten',
  'calendar.title': 'Agenda',
  'calendar.welcome': 'Welkom',

  // Quickplan Modal for Calendar
  'calendar.quickplan.close.all': 'Sluiten voor alle klassen',
  'calendar.quickplan.open': 'Openzetten',
  'calendar.quickplan.open.all': 'Openzetten voor alle klassen',
  'calendar.quickplan.plan': 'Plan in kalender',
  'calendar.quickplan.remove': 'Verwijder uit kalender',

  // Side calendar classes
  'side-calendar-classes.no-classes': 'Geen klassen geselecteerd',

  // Content
  'content.settings': 'Instellingen', // this is called 'Instellingen' instead of 'Overzicht' because at the moment, we can only set the view mode on the overview.
  'content.end_goals': 'Eindtermen',
  'content.what_are_you_looking_for': 'Wat zoek je?',
  'content.close.dialog': "Sluit",
  'content.results': 'Resultaten',
  'content.info': 'Informasjon',
  'content.infoLessons': 'Info lessons',
  'content.tags': 'Etiketter',
  'content.archive': 'Archiveren',
  'content.copyAndEdit': 'Kopiëren en bewerken in Studio',
  'content.edit': 'Bewerken',
  'content.unarchive': 'Dearchiveren',

  // Content overview
  'content-details.status': 'Status',
  'content-details.download': 'Documenten',

  // Results overview
  'results-overview.average': 'Gemiddelde',
  'results-overview.breadcrumbs.results.for.class': 'Resultaten voor {name}',
  'results-overview.calendar_item.breadcrumb.classes': 'Resultaten klassen ',
  'results-overview.calendar_item.breadcrumb.student': 'Resultaat student ',
  'results-overview.calendar_item.last_opened_at': 'Laatst geopend op',
  'results-overview.calendar_item.no-students-found': 'Er werden geen studenten gevonden voor deze les',
  'results-overview.calendar_item.no_results_scratch_no_judge': 'geen co-teacher',
  'results-overview.calendar_item.times_opened': 'Keren geopend',
  'results-overview.class': 'klas',
  'results-overview.column.graded_suffix': 'verbeterde open vragen',
  'results-overview.column.grading': 'Verbetering',
  'results-overview.column.name': 'Naam',
  'results-overview.column.progress': 'Vooruitgang',
  'results-overview.column.progress_suffix': 'vragen beantwoord door de student',
  'results-overview.column.results': 'Resultaten',
  'results-overview.column.score': 'Score',
  'results-overview.column.submitted_at': 'Ingezonden op',
  'results-overview.export_excel': 'Excel Spreadsheet',
  'results-overview.export_failed': 'Exporteren mislukt',
  'results-overview.export_smartschool': 'Score Puntenboekje',
  'results-overview.export_successful': 'Export met succes voltooid',
  'results-overview.home': 'thuis',
  'results-overview.made_in_version': 'Gemaakt in versie',
  'results-overview.no-results': 'Er zijn geen antwoorden ingediend',
  'results-overview.no_complete_average': 'Dit is een tijdelijk gemiddelde omdat niet alle vragen verbeterd zijn',
  'results-overview.not_all_questions_answered_warning': 'niet alle vragen beantwoord.',
  'results-overview.not_all_questions_have_scores_warning': 'onverbeterde vragen.',
  'results-overview.not_fully_graded': 'Niet alle antwoorden zijn verbeterd',
  'results-overview.not_fully_submitted': 'De student heeft nog niet alle antwoorden ingediend',
  'results-overview.publish_results': 'Publiceer resultaten',
  'results-overview.publish_results_warnings': 'Waarschuwing',
  'results-overview.student_has': 'leerling heeft',
  'results-overview.students_have': 'leerlingen hebben',
  'results-overview.title.class': 'Klas',
  'results-overview.title.results': 'Resultaten',
  'results-overview.unanswered_question': 'onbeantwoorde vraag',
  'results-overview.unanswered_questions': 'onbeantwoorde vragen',
  'results-overview.unpublish_results': 'Verberg resultaten',
  'results-overview.version': 'Versie',
  'results-overview.view': 'bekijk',
  'results_overview.calendar_item.loading': "Data aan het laden voor deze student, dat kan enkele seconden duren.",
  'results_overview.calendar_item.no_results': 'Deze student gaf nog geen antwoorden.',
  'results_overview.calendar_item.no_results_scratch': 'Deze student maakte nog geen Scratch oefeningen.',
  'results_overview.calendar_item.no_results_scratch_student': 'Je maakte nog geen Scratch oefeningen.',
  'results_overview.calendar_item.no_results_student': 'Je gaf nog geen antwoorden.',
  'results_overview.get.student_results': 'studentgegevens',
  'results_overview.questions.feedback': 'Feedback',
  'results_overview.results.for': "resultaten voor",
  'results_overview.results.for.student': "resultaat voor",
  'results_overview.score.between': 'De score moet tussen {min} en {max} liggen',
  'results_overview.student.result': "Studentresultaat",
  'results_overview.student.results': "Studentenresultaten",

  'student-answers.title.results': 'Resultaten',
  'student-answers.title.total': 'Totaal',
  'student-answers.question_label': 'vraag',
  'student-answers.no_questions': 'Er zijn geen vragen in deze weergave.',
  'student-answers.no_results_published': 'Er zijn nog geen resultaten gepubliceerd.',
  'student-answers.given_answer': 'Ingevuld antwoord',
  'student-answers.example_solution': 'Voorbeeldoplossing',
  'student-answers.click_to_show_question': 'Toon vraag',
  'student-answers.click_to_hide_question': 'Verberg vraag',
  'student-answers.no_content': 'Niets om te tonen',
  'student-answers.correct_answer': 'Het juiste antwoord is:',
  'student-answers.no_answer': 'Geen voorbeeld antwoord',

  'question-result.no-result': 'Er is geen antwoord voor deze vraag',

  // StudentSwitcher
  'student-switcher.next': 'volgende',
  'student-switcher.previous': 'vorige',

  // Errors
  'errors.401.header': "Onbevoegd",
  'errors.401.message.conclusion': "Indien je denkt dat je deze pagina onterecht te zien krijgt neem dan zeker contact met ons op via {email}.",
  'errors.401.message.cta': 'vraag een demo aan',
  'errors.401.message.description': "Wij herkennen je account niet, dit kan {amountOfReasons} oorzaken hebben:",
  'errors.401.message.intro': 'Welkom bij FTRPRF!',
  'errors.401.message.reason.no_contract_with_ftrprf': "Je school heeft geen overeenkomst afgesloten met ons. Voor meer info, neem een kijkje op onze website {website} of {cta}",
  'errors.401.message.reason.not_synchronized_with_smartschool': "De verantwoordelijke van je school heeft nog niet gesyncroniseerd met Smartschool, neem contact op met de verantwoordelijke.",
  'errors.403.header': 'Verboden toegang',
  'errors.403.message': "Je hebt geen toegang tot deze pagina.  Als je denkt dat je toegang zou moeten hebben, neem dan contact op met jouw school admin.",
  'errors.404.header': 'Pagina niet gevonden',
  'errors.404.message.main': "Je bent in onbekende wateren: deze pagina bestaat niet.",
  'errors.404.message.sub': "De pagina is mogelijks verwijderd of van URL veranderd.",
  'errors.action.home': 'Terug naar de homepage',
  'errors.back': 'Terug',
  'errors.category.missing.fields.no.other.errors.clarification': 'U kan hieronder terugvinden over welke gebruikers het gaat en welke velden er ontbreken.  We tonen zoveel mogelijk info om het oplossen van de fout te faciliteren.',
  'errors.category.missing.fields.no.other.errors.title': 'Er waren gebruikers met ongeldige velden.',
  'errors.category.tooManyUsers': 'Er mogen maximaal 2000 gebruikers in de excel zitten.  Splits de excel op zodat er maximaal 2000 gebruikers / file zijn.',
  'errors.category.userGenerationFailed': 'Het lukte niet om {user} aan te maken.',
  'errors.change_eula': 'kon gebruikersvoorwaarden niet wijzigen',
  'errors.change_language': 'Kon taal niet wijzigen',
  'errors.delete_chapter_item': `Er zijn één of meerdere lessen ingepland. Om deze les te kunnen verwijderen moeten alle gelinkte agendapunten verwijderd worden.`,
  'errors.message.no-page': 'Geen pagina gevonden met die URL',
  'errors.message.smartschool': `Je werd vandaag aangemaakt als gebruiker op het FTRPRF-platform. Pas vanaf morgen zal je account actief zijn en kan je het platform verkennen. Krijg je deze melding toch meerdere dagen na elkaar, neem dan contact op met je schoolbeheerder.`,
  'errors.no-classgroups': 'U bent niet gekoppeld aan een klas. Contacteer uw schoolbeheerder.',
  'errors.set_publish_status': 'Kon les status niet aanpassen',
  'errors.set_results_published': 'Kon resultaten niet publiceren',
  'errors.unknown': 'We weten niet wat er fout ging.  Als dit blijft duren, gelieve dan onze support te contacteren.',
  'errors.update_score': 'Kon score niet updaten',

  // Info Impersonator Among Us
  'impersonator.joke': "Pas op, er is een imitator",
  'impersonator.alt': "onder ons",
  "impersonator.header": 'Vergeet niet om je te gedragen als een normaal bemanningslid',

  // impersonate
  "impersonate.header": "We gaan aan boord, blijf alsjeblieft zitten...",
  "impersonate.header.failed": "We probeerden je aan boord te krijgen",
  "impersonate.description": "Helaas kan je nog niet aan je avontuur beginnen. Probeer zo dadelijk eens opnieuw!",

  // publishStatuses
  'publish-statuses.PUBLISHED': 'Publiceren',
  'publish-statuses.OPEN_FOR_HOME': 'Open voor thuisversie',
  'publish-statuses.OPEN_FOR_CLASSGROUP': 'Open voor klasversie',
  'publish-statuses.CLOSED': 'Gesloten',
  'publish-statuses.explanation.OPEN_FOR_HOME': 'De leerlingen zien de thuisversie van de les',
  'publish-statuses.explanation.OPEN_FOR_CLASSGROUP': 'De leerlingen zien de klasversie van de les',
  'publish-statuses.explanation.CLOSED': 'De leerlingen zien deze les niet',

  // curricula
  'curricula.overview': 'Leermateriaal',
  'curricula.overview.button': 'Overzicht leermateriaal',
  'curricula.overview.plan': 'Inplannen',
  'curricula.overview.plan.quick': 'Snel inplannen',
  'curricula.programs.empty': 'Er zijn momenteel nog geen modules gekoppeld aan jouw account. Neem contact op met de schoolbeheerder om inhoud te koppelen.',
  'curricula.LESSON.content-overview.TEACHER.empty': 'Er zijn nog geen lessen gepubliceerd',
  'curricula.LESSON.content-overview.STUDENT.empty': 'Er staan nog geen lessen open',
  'curricula.lessons.own': 'Eigen lessen',
  'curricula.lessons.school': 'Leermateriaal school',
  'curricula.lessons.own.none': 'Er zijn momenteel nog geen eigen lessen' +
    ' gepubliceerd.',
  'curricula.lessons.own.create': 'Ga gelijk aan de slag en maak je eigen' +
    ' les(sen)!',

  'curricula.EXAM.title': 'Toetsenoverzicht',
  'curricula.EXAM.content-overview.TEACHER.empty': 'Er zijn nog geen toetsen gepubliceerd',
  'curricula.EXAM.content-overview.STUDENT.empty': 'Er staan nog geen toetsen open',

  // Class Group
  'class-group.name': 'Naam',
  'class-group.details': 'Details',
  'class-group.linked': 'Gelinkt aan',
  'class-group.errors.something_went_wrong': "De klasgroep details kunnen op dit moment niet getoond worden",
  'class-group.same-name.error': 'Er bestaat al een klasgroep met deze naam',
  'class-group.add-user-to-class-group': 'Gebruiker toevoegen aan klasgroep',
  'class-group.add-user': 'Gebruikers toevoegen',

  // Profile
  'profile.calendar.showFullday': 'Toon de volledige dag ipv. de werkdag (8 - 20u)',
  'profile.calendar.showWeekends': 'Toon het weekend in de weekkalender',
  'profile.change_password': 'Wijzig wachtwoord',
  'profile.codeblocks': 'Thema',
  'profile.codeblocks.settings': 'Instellingen code blokken',
  'profile.codeblocks.showLineNumbers': 'Toon regelnummers',
  'profile.codeblocks.showMatchingBraces': 'Toon overeenkomende accolades',
  'profile.codeblocks.showRainbowBrackets': 'Toon regenbooghaakjes',
  'profile.confirm_new_password': 'Nieuw wachtwoord bevestigen',
  'profile.current_password': 'Huidig wachtwoord',
  'profile.default.password': 'Standaard wachtwoord',
  'profile.default.password.description': 'Minimaal 8 tekens.  De gebruiker zal gevraagd worden zijn wachtwoord te wijzigen bij de eerste login.',
  'profile.default.password.error': 'Wachtwoord is niet sterk genoeg',
  'profile.default.password.placeholder': 'Wachtwoord',
  'global.display_name': 'Weergavenaam',
  'profile.endDate': 'Einddatum',
  'profile.errors.change_password': 'Het wachtwoord kon niet gewijzigd worden.',
  'profile.errors.firstname.length': 'De gebruikersnaam moet minstens 1 en maximaal 64 tekens lang zijn',
  'profile.errors.lastname.length': 'De gebruikersnaam moet minstens 1 en maximaal 64 tekens lang zijn',
  'profile.errors.password.capital': 'Het wachtwoord moet grote en kleine letters bevatten',
  'profile.errors.password.length': 'Het wachtwoord moet minstens 8 tekens lang zijn',
  'profile.errors.password.number': 'Het wachtwoord moet een nummer bevatten',
  'profile.errors.passwords_not_matching': 'De nieuwe wachtwoorden komen niet overeen',
  'profile.errors.passwords_validation': 'Het nieuwe wachtwoord moet 1 hoofdletter, 1 normale letter en 1 nummer bevatten.',
  'profile.errors.something_went_wrong': 'De details van de gebruiker konden niet getoond worden.',
  'profile.errors.username.length': 'De gebruikersnaam moet minstens 1 en maximaal 64 tekens lang zijn',
  'profile.errors.username.pattern': "De gebruikersnaam kan enkel letters en cijfers bevatten",
  'profile.errors.wrong_current_password': 'Uw huidig wachtwoord is foutief',
  'profile.eula_accept': 'Accepteer de gebruikersvoorwaarden',
  'profile.eula_accepted': 'Gebruikersvoorwaarden geaccepteerd',
  'profile.first_name': 'Voornaam',
  'profile.language': 'Taal',
  'profile.language.choose': 'Kies uw gewenste taal',
  'profile.last_name': 'Achternaam',
  'profile.mail': 'E-mail',
  'profile.name': 'Naam',
  'profile.navLabel': 'Profielinfo',
  'profile.new_password': 'Nieuw wachtwoord',
  'profile.no_mail': 'Geen e-mailadres',
  'profile.notifications': 'Lijst met aankondigingen',
  'profile.notifications.empty': 'Er zijn momenteel geen aankondigingen',
  'profile.preferences': 'Voorkeuren',
  'profile.role': 'Rol',
  'profile.settings_being_retrieved': 'Uw instellingen worden opgehaald, even geduld a.u.b.',
  'profile.slideviewer': 'Slides',
  'profile.slideviewer.viewMode': 'Standaard weergavemodus',
  'profile.success.change_password': 'Uw wachtwoord is successvol gewijzigd',
  'profile.teams.assignmentAddToCalendarActions': 'Bij wie wil je dat dit in de Agenda verschijnt?',
  'profile.teams.assignmentAddToCalendarActions.NONE': 'Niemand',
  'profile.teams.assignmentAddToCalendarActions.STUDENTS_AND_TEAM_OWNERS': 'Leerlingen en leerkrachten',
  'profile.teams.assignmentAddToCalendarActions.STUDENTS_ONLY': 'Leerlingen',
  'profile.teams.sendTo': 'Stuur naar teams',
  'profile.title': 'Profiel',
  'profile.username': 'Gebruikersnaam',

  // HelpVideos
  'help_videos.categories': 'Categorieën',
  'help_videos.categories.sr.link': 'Bekijk alle video\'s in de categorie {category}',
  'help_videos.featured': 'Uitgelicht',
  'help_videos.title': "Hulpvideo's",
  'help_videos.videos': 'Video\'s',
  'help_videos.short.description.title': 'Meer info ',


  // password strength
  'password-strength.very_weak': 'Zeer zwak wachtwoord',
  'password-strength.weak': 'Zwak wachtwoord',
  'password-strength.almost_there': 'Je bent er bijna',
  'password-strength.good': 'Goed wachtwoord',
  'password-strength.excellent': 'Uitstekend wachtwoord',
  'password.dialog.generate-password': 'Genereer wachtwoord',

  // FeedbackPopover
  "feedback-popover.feedback": "Feedback",
  "feedback-popover.placeholder": "Voeg hier je feedback toe",

  // ContentSlideViewer
  'slideviewer.error': 'Er zijn geen slides voor deze les.',
  'slide_viewer.scroll_up': 'Scroll naar boven',
  'slide_viewer.scroll_down': 'Scroll naar beneden',
  'content-slide-viewer.no-slides': 'Geen slides',
  'content-slide-viewer.no-slides.description': 'Deze les bevat geen slides' +
    ' voor de huidige weergave-modus. Kies een andere:',
  'content-slide-viewer.no-slides.description-student': 'Deze les lijkt geen slides te bevatten',
  'content-slide-viewer.forgot-to-save': 'Vergeten indienen?',
  'content-slide-viewer.forgot-to-save-description': 'Je antwoord op deze vraag is nog niet ingediend. Wens je in te dienen?',

  // ContentSlideViewer HardSubmitSlide
  'content-slide-viewer.hard-submit-slide.title': 'Indienen',
  'content-slide-viewer.hard-submit-slide.description': 'Bij het indienen van de toets zal het niet meer mogelijk zijn om je antwoorden te wijzigen. Ben je zeker dat je wil indienen?',
  'content-slide-viewer.hard-submit-slide.submit': 'Dien in',
  'content-slide-viewer.hard-submit-slide.submitted': 'Ingediend',
  'content-slide-viewer.hard-submit-slide.success': 'De toets is succesvol ingediend',
  'content-slide-viewer.hard-submit-slide.error': 'Er liep iets fout bij het indienen van de toets. Probeer opnieuw.',

  // Scratch
  'scratch.header.new_project': 'Nieuw project',
  'scratch.header.start_over': 'Begin opnieuw',
  'scratch.header.upload-exercise': 'Upload oefening',
  'scratch.header.turbo': 'Turbo',
  'scratch.header.by': 'Door',
  'scratch.scratch_error': 'Je Scratch oefening kon niet geladen worden...',
  'scratch.confirmation.content': 'Ben je zeker dat je de oefening wil herstarten?',

  // Coach Codi
  'codi.messages.tests_loading': 'Ik ben je oefening aan het testen. Dit kan even duren.',
  'codi.messages.tests_more_than_half_correct': 'Meer dan de helft van mijn testen werken al! Goed bezig!',
  'codi.messages.tests_more_all_correct': 'Alle testen zijn geslaagd! Goed gewerkt!',
  'codi.messages.test_ready': 'Er staan testresultaten voor je klaar.',
  'codi.messages.welcome': 'Hallo, ik ben Coach Codi! Ik zal jouw code testen. Druk op de knop rechts om te beginnen.',


  // Program usage overview
  'program-usage-overview.chapter': 'Les',
  'program-usage-overview.classes': 'Klassen',
  'program-usage-overview.classes.filter': 'Filter op klas',
  'program-usage-overview.empty': 'Er zijn nog geen studenten die gebruik maken van dit programma.',
  'program-usage-overview.empty.search': 'Er zijn geen studenten gevonden die voldoen aan deze zoekcriteria.',
  'program-usage-overview.name': 'Student',
  'program-usage-overview.no-access': 'U heeft geen toegang tot dit programma voor deze periode.  Gelieve contact op te nemen met een admin indien u denkt dat dit een fout is.',
  'program-usage-overview.start': 'Starttijd',
  'program-usage-overview.teacher': 'Leerkracht',


  // Student overview
  'student-overview.classes': 'Klassen',
  'student-overview.courses': 'Cursussen',
  'student-overview.users': 'Gebruikers',
  'student-overview.title': 'Studenten',
  'student-overview.column.name': 'Naam',
  'student-overview.column.endDate': 'Einddatum',
  'student-overview.column.status': 'Status',
  'student-overview.column.username': 'Gebruikersnaam',
  'student-overview.column.class': 'Klas',
  'student-overview.column.reset-password.header': 'Reset wachtwoord',
  'student-overview.column.reset-password.info': 'Bevestig met je eigen wachtwoord om de reset uit te voeren',
  'student-overview.column.reset-password.repeat': 'Geef het gewenste wachtwoord in',
  'student-overview.column.reset-password.label': 'Nieuw wachtwoord',
  'student-overview.column.reset-password.label.repeat-password': 'Herhaal het nieuw wachtwoord',
  'student-overview.column.reset-password.confirm': 'Reset',
  'student-overview.column.reset-password.error': 'Fout bij resetten van wachtwoord',
  'student-overview.column.delete-student.success': "Student werd verwijderd",
  'student-overview.column.delete-student.error': "Er liep iets fout tijdens het verwijderen",
  'student-overview.column.edit-student.success': "Student werd gewijzigd",
  'student-overview.column.edit-student.error': "Er liep iets fout tijdens het wijzigen",
  'student-overview.column.reset-password.confirmation': 'Het nieuwe wachtwoord werd gekopieerd naar je klembord',
  'student-overview.new-student': "Nieuwe student",
  'student-overview.add-student': "Voeg student toe",
  'student-overview.no-students': "Deze klas bevat geen studenten",
  'student-overview.column.create-student.success': "Nieuwe student aangemaakt, wachtwoord gekopieerd naar klembord.",
  'student-overview.column.create-student.error': "Er liep iets fout tijdens het aanmaken.",
  'student-overview.are-you-sure': "Ben je zeker dat je deze student wil verwijderen?",

  // Teacher overview
  'teacher-overview.classes': 'Klassen',

  // items overview
  'classgroup-overview.column.name': 'Naam',
  'classgroup-overview.column.archived': 'Gearchiveerd',
  'classgroup-overview.archived.true': 'Gearchiveerd',
  'classgroup-overview.archived.false': 'Niet gearchiveerd',
  'classgroup-overview.not-archived': 'Toon gearchiveerde klasgroepen',

  // Organization overview
  'organization-overview.button.add-organization': 'Nieuwe organisatie',
  'organization-overview.column.issuer': 'Uitgever',
  'organization-overview.column.language': 'Taal',
  'organization-overview.column.name': 'Naam',
  'organization-overview.column.usernamePrefix': 'Voorvoegsel',
  'organization-overview.empty': 'Er zijn geen organisaties gevonden die voldoen aan deze zoekcriteria',
  'organization-overview.error': "Er is een fout opgetreden tijdens het laden van de organisaties",
  'organization-overview.calendar.legend': 'Kalender status',
  'organization-overview.calendar.off': 'uit',
  'organization-overview.calendar.on': 'aan',
  'organization-overview.not-active': 'Inactieve organisaties',
  'organization-overview.title': 'Organisaties',

  // Users overview
  'users-overview.confirmation-text': "Bent u zeker dat u deze gebruiker wilt verwijderen?",
  'users-overview.disable': "Gebruiker deactiveren",
  'users-overview.enable': "Gebruiker activeren",
  'users-overview.error': "Er is een fout opgetreden tijdens het laden van de gebruikers",
  'users-overview.firstName': "Zoek op voornaam",
  'users-overview.lastName': "Zoek op achternaam",
  'users-overview.use-disable-text': "Wij raden ten zeerste aan om de gebruiker te deactiveren.  Verwijderen van gebruikers is definitief en kan tot data corruptie leiden (bv. gebroken links), bij deactiveren kan u dit wel nog terugdraaien.",
  'users-overview.username': "Zoek op gebruikersnaam",

  // Program overview
  'program-overview.column.name': 'Naam',
  'program-overview.column.seatCount': "Plaatsen",
  'program-overview.column.seatPeriod': "Boekingsperiode",
  'program-overview.column.type': 'Programma Type',
  'program-overview.empty': "Voor deze organisatie zijn er geen programma's gekoppeld aan deze periode.",
  'program-overview.empty.search': "Er zijn geen programma's gevonden die voldoen aan dit zoekcriterium.",
  'program-overview.error': "Er is een fout opgetreden tijdens het laden van de programma's",
  'program-overview.new-program': "Programma toevoegen",
  'program-overview.title': "Programma's",

  // Chapter overview
  'chapter-overview.search.placeholder': 'Zoek op naam',
  'chapter-overview.select.placeholder': 'Filter op label',

  // ChapterItem overview
  'chapterItem-overview.search.placeholder': 'Zoek op naam',
  'chapterItem-overview.select.placeholder': 'Filter op label',

  // Classes overview
  "classes-overview.view.students": "Leerlingen",
  "classes-overview.view.lessons": "Lessen",
  "classes-overview.view.exercises": "Oefeningen",
  "classes-overview.view.tests": "Toetsen",
  "classes-overview.column.classname": "Klasnaam",
  "classes-overview.column.students": "Leerlingen",
  'classes-overview.error': "Er is een fout opgetreden tijdens het laden van de klassen",

  // Class detail
  "class-detail.pageTitle": "Klas overzicht",

  // Students overview
  "students-overview.column.email": "E-mail",
  "students-overview.column.name": "Naam",
  "students-overview.column.profiletest": "Profieltoets",
  "students-overview.column.username": "Gebruikersnaam",
  'students-overview.error': "Er is een fout opgetreden tijdens het laden van de studenten",
  'students-overview.search.email': 'Zoeken op e-mail',
  'students-overview.search.username': 'Zoeken op gebruikersnaam',
  'students-overview.exportResults.COMPLETED': 'Het doorsturen van de resultaten naar {where} slaagde op {dateTime}.',
  'students-overview.exportResults.export': 'Publiceer naar {where}',
  'students-overview.exportResults.FAILED': 'Het doorsturen van de resultaten faalde op {dateTime}.',
  'students-overview.exportResults.isLoading': 'Er werden nog geen punten doorgestuurd naar {where}.',

  // Lesson overview
  'lessons-overview.error': 'Er is een fout opgetreden tijdens het laden van de lessen',
  'lessons-overview.column.author': 'Auteur',
  'lessons-overview.column.type': 'Type',
  'lessons-overview.column.startDate': 'Startdatum',
  'lessons-overview.column.date': 'Datum',
  'lessons-overview.column.endDate': 'Einddatum',
  'lessons-overview.column.moment': 'Lesmoment',
  'lessons-overview.column.chapterItemName': 'Lesnaam',
  'lessons-overview.search': 'Zoeken op lesnaam',
  'lessons-overview.filterTeacher': 'Filter op leerkracht',
  'lessons-overview.range': 'Bereik',
  'lessons-overview.planned_start': 'ingepland op:',
  'lessons-overview.planned_end': 'ingepland op:',
  'lessons-overview.at': "om",
  'lessons-overview.from': "van",
  'lessons-overview.until': "tot",
  'lessons-overview.title': 'Klasresultaten',

  // exercise overview
  'exercises-overview.column.endDate': 'Einddatum',
  'exercises-overview.column.startDate': 'Begindatum',
  'exercises-overview.column.title': 'Titel',
  'exercises-overview.search': 'Zoeken op titel',
  'exercises-overview.range': 'Bereik',
  'exercises-overview.planned_start': 'ingepland op:',
  'exercises-overview.planned_end': 'ingepland op:',
  'exercises-overview.at': "om",

  // Lesson results overview
  'lesson-results-overview-klasgemiddelde': 'Klasgemiddelde',
  'lesson-results-overview-search': 'Zoeken op klas',
  'lesson-results-class-not-found': 'Er zijn geen klassen te vinden voor de zoekopdracht',

  // Manage program details
  'manage-program-details.name': "Naam",
  'manage-program-details.type': "Programmatype",
  'manage-program-details.edit': "Bewerk programma",
  'manage-program-details.no-chapters': "Geen hoofdstukken",
  'manage-program-details.no-chapter-items': "Geen lessen",
  'manage-program-details.add-chapter': "Hoofdstuk toevoegen",
  'manage-program-details.edit-chapter': "Hoofdstuk bewerken",
  'manage-program-details.delete-chapter': "Hoofdstuk verwijderen",
  'manage-program-details.add-chapter-item': "Les toevoegen",
  'manage-program-details.chapter-item': "Onderdeel",
  'manage-program-details.chapter-items': "Onderdelen",
  'manage-program-details.delete-chapter-item': "Les verwijderen",
  'manage-program-details.edit-chapter-item': "Les bewerken",
  'manage-program-details.error': "Er is een fout opgetreden tijdens het laden van het programma",

  // Add program dialog
  'add-program-dialog.header': "Nieuw programma maken",
  'add-program-dialog.error': "Er liep iets fout bij het maken van een nieuw programma",
  'add-program-dialog.name': "Naam",
  'add-program-dialog.type': "Programmatype",
  'add-program-dialog.add': "Programma toevoegen",
  'add-program-dialog.color': "Kleur toevoegen",
  'add-program-dialog.file': "Bestand toevoegen",

  // Update chapter
  "update-chapter-dialog.header": "Update hoofdstuk",

  // Link programs
  'link-programs-dialog.header': "Programma aan leerkracht koppelen",
  'link-programs-dialog.confirm': "Bevestigen",
  'link-programs-dialog.label': "Programma's",
  'link-program-dialog.all-programs-linked': "Alle programma's gekoppeld",

  // Unlink programs
  'unlink-programs-dialog.header': "Leerkracht van programma ontkoppelen",
  'unlink-programs-dialog.confirm': "Bevestigen",
  'unlink-programs-dialog.label': "Programma's",
  'unlink-program-dialog.all-programs-unlinked': "Alle programma's ontkoppeld",

  // Add classgroups dialog
  "add-classgroups-dialog.header": "Voeg gebruiker toe aan klas",
  "add-classgroups-dialog.label": "Klassen",
  "add-classgroups-dialog.description": "Niet alle klassen worden getoond. Zoek op klasnaam om de juiste klas te vinden.",
  "add-classgroups-dialog.placeholder": "Zoek op klasnaam",
  "add-classgroups-dialog.confirm": "Bevestigen",
  "link-classgroup-dialog.all-classgroups-linked": "Alle klassen gekoppeld",


  // date range dialog
  "date-range-dialog.header": "Bepaal bereik",
  "date-range-dialog.apply": "Toepassen",

  // Add or update organization dialog
  'aou-org.add': "Organisatie toevoegen",
  'aou-org.domainName': "Domeinvoorvoegsel",
  'aou-org.domainName.description': 'Als u een domein: "https://domeinvoorvoegsel.smartschool.be" heeft, moet u het domeinvoorvoegsel opgeven',
  'aou-org.import.classes': "Importeer klassen",
  "aou-org.import.clientId": "Client-ID",
  "aou-org.import.clientSecret": "Client-secret",
  "aou-org.import.membershipOfStudentsToClasses": "Lidmaatschap van studenten importeren in lesgroepen",
  "aou-org.import.membershipOfTeachersToClasses": "Lidmaatschap van leekrachten importeren in lesgroepen",
  "aou-org.import.one-roster.title": "One Roster gegevens",
  'aou-org.import.students': "Importeer studenten",
  'aou-org.import.teachers': "Importeer leerkrachten",
  'aou-org.issuer': "Aanmeld platform",
  'aou-org.language': "Kies de taal voor de organisatie",
  'aou-org.name': "Organisatie naam",
  'aou-org.prefix': "Organisatie prefix",
  'aou-org.prefix-error': "Dit voorvoegsel bestaat al",
  'aou-org.submit': "Organisatie aanmaken en periode toevoegen",
  'aou-org.tenantId': "Tenant id",
  'aou-org.update': "Organisatie bewerken",


  // Activate program dialog
  'activate-organization-dialog.header': "Activeer organisatie",
  'activate-organization-dialog.error': "Er liep iets fout bij het activeren van deze organisatie",
  'activate-organization-dialog.confirm': "Activeer",
  'activate-organization-dialog.confirmation-text': 'Bent u zeker dat u de "{organisationName}" organisatie wilt activeren?',

  // Deactivate program dialog
  'deactivate-organization-dialog.header': "Organisatie deactiveren",
  'deactivate-organization-dialog.error': "Er liep iets fout bij het deactiveren van deze organisatie",
  'deactivate-organization-dialog.confirm': "Deactiveer",
  'deactivate-organization-dialog.confirmation-text': 'Bent u zeker dat u de "{organisationName}" organisatie wilt deactiveren?',

  // Add class group dialog
  'add-class.group-dialog.header': "Maak een nieuwe klasgroep",
  'add-class.group-dialog.error': "Er liep iets fout bij het aanmaken van deze klasgroep",
  'add-class.group-dialog.confirm': "Aanmaken",
  'add-class.group-dialog.name': "Naam klasgroep",
  'add-class.group-dialog.add': "Voeg klasgroep toe",

  // Delete class group dialog
  'delete-class.group-dialog.header': 'Archief:',
  'delete-class.group-dialog.content': 'Bent u zeker dat u dit wilt archiveren?',

  // Delete program dialog
  'delete-program-dialog.header': "Verwijder programma",
  'delete-program-dialog.error': "Er liep iets fout bij het verwijderen van een nieuw programma",
  'delete-program-dialog.confirm': "Verwijderen",
  'delete-program-dialog.confirmation-text': "Weet u zeker dat u het programma wilt verwijderen?",

  // Delete user dialog
  'delete-user-dialog.header': "Gebruiker verwijderen",
  'delete-user-dialog.error': "Er liep iets fout bij het verwijderen van de gebruiker",
  'delete-user-dialog.confirm': "Verwijderen",
  'delete-user-dialog.confirmation-text': "Bent u zeker dat u de gebruiker wilt verwijderen met de naam",

  // Deactivate user dialog
  'deactivate-user-dialog.header': "Gebruiker deactiveren",
  'deactivate-user-dialog.confirm': "Deactiveren",
  'deactivate-user-dialog.confirmation-text': "Bent u zeker dat u {user} wilt deactiveren?",

  // Link user to class dialog
  'link-user.to.class-dialog.header': "Gebruiker linken aan klasgroep",
  'link-user.to.class-dialog.add': "Toevoegen aan klasgroep",
  'link-user.to.class-dialog.confirm': "Update",

  // Update program dialog
  'update-program-dialog.color': "Kleur aanpassen",
  'update-program-dialog.confirm': "Organisatie aanpassen",
  'update-program-dialog.error': "Er liep iets fout bij het bijwerken van het programma",
  'update-program-dialog.header': "Bewerk programma",
  'update-program-dialog.name': "Naam programma",
  'update-program-dialog.type': "Programmatype",

  // Update class group
  'update-class.group-dialog.confirm': "Klasgroep updaten",

  // chapter dialog
  'chapter-dialog.document': 'Link document',
  'chapter-dialog.document.description': 'Documenten die met het hoofdstuk te maken hebben.',
  'chapter-dialog.name': "Hoofdstuknaam",
  'chapter-dialog.weight.description': "Zal gebruikt worden bij het sorteren van hoofdstukken.",

  // Add chapter dialog
  'add-chapter-dialog.header': "Maak een nieuw hoofdstuk",
  'add-chapter-dialog.error': "Er liep iets fout bij het aanmaken van een nieuw hoofdstuk",
  'add-chapter-dialog.add': "Hoofdstuk toevoegen",


  // Delete chapter dialog
  'delete-chapter-dialog.header': "Hoofdstuk verwijderen",
  'delete-chapter-dialog.error': "Er liep iets fout bij het verwijderen van het nieuwe hoofdstuk",
  'delete-chapter-dialog.confirm': "Verwijderen",
  'delete-chapter-dialog.confirmation-text': "Weet u zeker dat u een hoofdstuk wilt verwijderen?",

  // chapter item dialog
  'chapter-item-dialog.document': 'Link document',
  'chapter-item-dialog.document.description': 'Documenten die met de les te maken hebben.',
  'chapter-item-dialog.weight.description': "Zal gebruikt worden bij het sorteren van lessen.",

  // Add chapter item dialog
  'add-chapter-item-dialog.header': "Voeg een les toe",
  'add-chapter-item-dialog.error': "Er liep iets fout bij het toevoegen van een les",
  'add-chapter-item-dialog.name': "Naam les",
  'add-chapter-item-dialog.lesson-content-id': "ID lesinhoud",

  // Delete chapter dialog
  'delete-chapter-item-dialog.header': "Les verwijderen",
  'delete-chapter-item-dialog.error': "Er liep iets fout bij het verwijderen van de les",
  'delete-chapter-item-dialog.confirm': "Verwijderen",
  'delete-chapter-item-dialog.confirmation-text': "Weet u zeker dat u een les wilt verwijderen?",

  // Update chapter item dialog
  'update-chapter-item-dialog.header': "Hoofdstukonderdeel bewerken",
  'update-chapter-item-dialog.error': "Er liep iets fout bij het bijwerken van het hoofdstukonderdeel?",
  'update-chapter-item-dialog.name': "Naam hoofdstukonderdeel",
  'update-chapter-item-dialog.lesson-content-id': "ID lesinhoud",

  // program dialog
  'program-dialog.color.description': "Zal gebruikt worden bij het voorstellen van programma's.",
  'program-dialog.document': 'Link document',
  'program-dialog.document.description': 'Documenten die met het programma te maken hebben.',
  'program-dialog.weight.description': "Zal gebruikt worden bij het sorteren van programma's.",

  // Add program dialog
  'add-program-dialog.add-to-organization': "Voeg programma toe aan organisatie",
  'add-program-dialog.close': "Sluit",
  'add-program-dialog.what_are_you_looking_for': 'Wat zoek je?',
  'add-program-dialog.seat-count': "Plaatsen",
  'add-program-dialog.enter.seat-count': "Geef het aantal plaatsen in",

  // Edit seats for program dialog
  'edit-seats-for-program.change': "Verander aantal plaatsen voor ",
  'edit-seats-for-program.submit': "Pas plaatsen aan",
  'edit-seats-for-program.edit': "Pas aantal plaatsen aan",

  // Edit period for program dialog
  'edit-period-for-program.change': "Verander periode voor ",
  'edit-period-for-program.submit': "Pas periode aan",
  'edit-period-for-program.edit': "Pas periode aan",
  'edit-period-for-program.seatPeriod.choose': "Kies een boekingsperiode",

  // Schooladmin overview
  'global.first_name': 'Voornaam',
  'global.last_name': 'Achternaam',
  'global.first_and_last_name': 'Voor- en achternaam',
  'schooladmin-overview.new-user': 'Nieuwe {what}',
  'schooladmin-overview.new-class-group': 'Nieuwe klasgroep',
  'schooladmin-overview.new-schooladmin': 'Schoolbeheerder toevoegen',
  'schooladmin-overview.edit-schooladmin': 'Schoolbeheerder aanpassen',

  // Schooladmin dialog
  'delete-schooladmin-header': 'Schoolbeheerder verwijderen',
  'email.validate-error': 'Ongeldig e-mailformaat',

  // Schooladmin notifications
  'schooladmin.error': 'Oeps! Er is iets misgegaan.',
  'schooladmin.success.add': 'Schooladmin succesvol toegevoegd',
  'schooladmin.success.delete': 'Schooladmin succesvol verwijderd',
  'schooladmin.success.edit': 'Schooladmin succesvol bewerkt',

  // userProfileSchooladmin
  'schooladmin.classes.empty': 'Deze {what} is nog niet gekoppeld aan een klas',
  'schooladmin.programs.empty': 'Deze {what} is nog niet gekoppeld aan een programma',

  // Program detail schooladmin
  'schooladmin.program.detail.empty': 'Er zijn momenteel nog geen hoofdstukken gelinkt aan dit programma.  Vraag aan een admin om dit te doen.',

  // Program usage schooladmin
  'schooladmin.program.usage.empty': 'Momenteel wordt er geen gebruik gemaakt van dit programma',

  // Program detail admin
  'admin.program.detail.empty': 'Er zijn momenteel nog geen hoofdstukken gelinkt aan dit programma.',

  // Add student dialog
  'add-user-dialog.header': "Nieuwe {what} toevoegen",
  'add-user-dialog.success': "Student succesvol aangemaakt",
  'add-user-dialog.error': "Er liep iets fout bij het aanmaken van een student",
  'add-user-dialog.confirm': "Maak aan",
  'add-user-dialog.linked_classgroups': "Gelinkte klasgroepen",
  "add-user-dialog.user-type": "Wat voor type gebruiker?",

  // Add teacher dialog
  'add-teacher-dialog.header': "Nieuwe leerkracht toevoegen",
  'add-teacher-dialog.success': "Succesvol leerkracht aangemaakt",
  'add-teacher-dialog.error': "Er liep iets fout bij het aanmaken van een" +
    " leerkracht",
  'add-teacher-dialog.confirm': "Maak aan",
  'add-teacher-dialog.linked_classgroups': "Gelinkte klasgroepen",


  // hack room
  'hack-room.featured': 'Uitgelicht',
  'hack-room.more_lessons': 'Meer lessen',
  'hack-room.what_are_you_looking_for': 'Wat zoek je?',
  'hack-room.select': 'Filter op label',

  // Exercises
  'exercises-overview.title': 'Oefeningen van',
  'exercises-overview.pretitle': 'Oefeningen',
  'exercises-overview.my-exercises.title': 'Mijn oefeningen',
  'exercises-overview.no_exercises': 'Geen oefeningen beschikbaar',
  'exercises-overview.create': 'Maak een nieuwe oefening',
  'exercises-overview.error': 'Fout bij het laden van de oefeningen',
  'exercises-overview.view_exercises': 'Bekijk oefeningen',
  'exercises-overview.delete_success': 'De oefening werd verwijderd',
  'exercises-overview.delete_fail': 'Fout bij het verwijderen van de oefening',
  'exercises-overview.toggle_filter': 'Filter',
  'exercises-overview.filter_placeholder': 'Titel',
  'exercises-overview.label.filter': 'Filter op titel',

  // scratch gui
  'gui.ftrprf.testresult.click_to_test': 'Test je code!',
  'gui.ftrprf.testresult.failed': 'De test is gefaald',
  'gui.ftrprf.testresult.hide_subtests': 'Verberg alle deeltesten',
  'gui.ftrprf.testresult.loading': 'Loading',
  'gui.ftrprf.testresult.of': 'Test van: ',
  'gui.ftrprf.testresult.show_subtests': 'Toon alle deeltesten',
  'gui.ftrprf.testresult.test': 'Test',
  'gui.ftrprf.testresult.tests': 'Testen',
  'gui.ftrprf.test-tab': 'Coach Codi',
  'gui.ftrprf.test-tab.latest_test': 'Laatste testresultaat',
  'gui.ftrprf.test-tab.loading': 'Aan het controleren...',
  'gui.ftrprf.test-tab.old_tests': 'Oude testresultaten',
  'gui.ftrprf.contextMenu.hide_blocks': 'Verberg blokken',
  'gui.ftrprf.contextMenu.display_blocks': 'Toon blokken',

  // excercise versions
  'scratch.version.STARTER': 'Starter',
  'scratch.version.SOLUTION': 'Oplossing',
  'scratch.version.DEMO': 'Demo',
  'scratch.version.EXTRA': 'Extra',
  'scratch.version.OTHER': 'Andere',

  // Codi
  'assistant.name': "Coach {name}",
  'assistant.always-here-to-help': "Altijd hier om jou te helpen.",
  'assistant.chat.need-help': 'Hulp nodig? Klik hier voor een hint.',
  'assistant.getToKnow.title': 'Ik ben coach Codi!',
  'assistant.getToKnow.body': 'Hallo, ik ben Coach CoDi, je coding-coach. Ik kan je vertellen of je oefeningen gelukt zijn en kan je tips geven wat je nog beter kan doen. Je kan me vinden via de ‘Coach CoDi’ tab in het menu bovenaan.',
  'assistant.getToKnow.screenshot': 'De Codi tab in Scratch is de laatste tab, rechts van de geluiden tab.',

  // Manage
  'manage.title': "Beheer",
  'manage.edit-school': "Wijzig school",
  'manage.courses': "Cursussen",

  // personality
  'personality.test.send': "Verzend persoonlijkheidstesten",
  'personality.test.form.send': "Testen verzenden",
  'personality.test.adults': "Volwassenen",
  'personality.test.children': "Kinderen",

  // groups
  'groups.group': "Groep",
  'groups.members': "Leden",
  'groups.compose.divide': "Groepen samenstellen",
  'groups.compose.type': "Welk soort samenstelling wil je?",
  'groups.compose.divider.amount': "Aantal groepen",
  'groups.compose.divider.create': "Groepen aanmaken",
  'groups.compose.option.random': "Willekeurige samenstelling",
  'groups.compose.option.steams': "STEAMS samenstelling",
  'groups.compose.randomize.members': "Groepen herverdelen",
  'groups.compose.randomize.roles': "Rollen herverdelen",
  'groups.compose.steams.activity.created': 'STEAMS activiteit',
  'groups.compose.steams.activity.error': 'de STEAMS activiteit',
  'groups.compose.steams.loading': "Data aan het laden van STEAMS, dit duurt" +
    " een aantal seconden",
  'groups.compose.steams.noiframe': 'Uw browser ondersteunt deze feature niet',
  'groups.compose.save': "Groepen opslaan",
  'groups.compose.start': "Groepssamenstelling starten",
  'groups.compose.memberrow.roles': "Choisissez un rôle pour cet étudiant",
  'groups.save.lesson.success': 'Groepen',
  'groups.save.lesson.error': 'groepen',

  // Results
  'sendResults.success': "Resultaten succesvol naar {what} verzonden",
  'sendResults.error': "Er ging iets mis tijdens het versturen van de resultaten naar {what}",


  // eventpopup
  'eventpopup.divide_groups': 'Verdeel groepen',
  'eventpopup.delete.past_event': "Je kan een event in het verleden niet verwijderen",

  // filter
  'filter.clear': 'Verwijder filters',

  // Logout
  'logout.title': 'Bezig met uitloggen',
  'logout.extra': 'Even geduld, we loggen je uit op al onze platformen...',

  // Reports
  'global.REPORT.singular': 'Rapport',
  'global.REPORT.plural': 'Rapporten',

  'reports.SEAT_UPDATE_FREQUENCY': "update elk uur",
  'reports.SEAT_UPDATE_FREQUENCY_EXPLANATION': "Dit is geen live data: het aantal gebruikte seats wordt elk uur herberekend.",
  'reports.SEAT_USAGE': 'Seat gebruik',
  'reports.SEAT_USAGE.extra': 'Rapport dat voor per organisatie, per programma het aantal gebruikte seat en het toegelaten seats weergeeft',

  'reports.CALENDAR_ITEM_USAGE': 'Calender Items Gebruik',
  'reports.CALENDAR_ITEM_USAGE.extra': 'Geeft per organisatie weer hoeveel kalender items er zijn per les/module/pakket',

  'reports.PROGRAM_USAGE': "Programma's gebruik",
  'reports.PROGRAM_USAGE.extra': "Geeft per organisatie, per programma weer hoeveel unieke gebruikers er mogelijks toegang hebben aan de hand van kalender items en het maximum aantal seats.",

  'reports.COMBINED_PROGRAMS_USAGE': 'Paketten gebruik',
  'reports.COMBINED_PROGRAMS_USAGE.extra': 'Geeft per organisatie voor de Pakketten IT&ME en WIJS (1ste en 2de graad) weer hoeveel unieke gebruikers er mogelijks toegang hebben aan de hand van kalender items',

  'reports.STUDENTS_OVERVIEW_ITANDME': 'Leerlingenoverzicht IT&ME',
  'reports.STUDENTS_OVERVIEW_ITANDME.extra': 'Een lijst van alle unieke leerlingen die mogelijks toegang hebben tot een les van IT&ME (1ste en 2de graad)',

  'reports.STUDENTS_OVERVIEW_WIJS': 'Leerlingenoverzicht WIJS',
  'reports.STUDENTS_OVERVIEW_WIJS.extra': 'Een lijst van alle unieke leerlingen die mogelijks toegang hebben tot een les van WIJS (1ste en 2de graad)',

  'reports.STUDENTS_PER_LESSON': 'Leerlingenoverzicht per les',
  'reports.STUDENTS_PER_LESSON.extra': 'Overzicht van welke lessen er zijn opgengezet voor welke klassen, hoeveel leerlingen daarin zitten en welke leerkracht dit heeft opengezet',

  'reports.SLIDES_OPENED': 'Geopende lessen per gebruiker',
  'reports.SLIDES_OPENED.extra': 'Uitgebreide lijst van elke keer een gebruiker een les heeft open gedaan, inclusief timestamp',

  'reports.LOGGED_IN': 'Logins per gebruiker',
  'reports.LOGGED_IN.extra': 'Uitgebreide lijst van elke keer dat een gebruiker ingelogd is op het platform',

  'reports.TEACHERS_EXPORT': 'Leerkrachten',
  'reports.TEACHERS_EXPORT.extra': 'Lijst van alle Leerkrachten en schooladmins, inclusief email en newsletter toestemming',

  // Tags
  'global.TAG.singular': 'Label',
  'global.TAG.plural': 'Labels',

  // Periods
  'global.period': 'Periode',
  'global.periods': 'Periodes',
  'periods.active': 'Actief',
  'periods.delete-confirmation': 'Ben je zeker dat je deze periode wil verwijderen?',
  'periods.description': 'Beschrijving',
  'periods.endDate': 'Einddatum',
  'periods.errors.dates-overlap': "De periode mag niet overlappen met een bestaande periode.",
  'periods.errors.startdate-before-enddate': 'De startdatum moet voor de einddatum liggen.',
  'periods.new': 'Nieuwe periode',
  'periods.new.for': 'Nieuwe periode voor',
  'periods.selector.select-period': "Selecteer een periode",
  'periods.startDate': 'Begindatum',
  'periods.submit': 'Periode aanmaken',

  // Admin move lesson
  'tools-move-lesson.description': ` Via deze pagina kan je lessen uit studio verplaatsen van de ene organisatie naar de andere. Verplaatsten heeft als bijkomend voordeel dat de slideID's gelijk blijven en de slidelinks dus blijven werken.`,
  'tools-copy-lesson.description': ` Via deze pagina kan je lessen uit studio kopieren van de ene organisatie naar de andere.`,
  'move-lesson.method': 'Methode',
  'move-lesson.method.move': 'Verplaatsen',
  'move-lesson.method.copy': 'Kopieren',

  // Link teachers to programs
  'link-teachers-programs.button': 'Link alle leerkrachten aan alle programma’s',
  'link-teachers-programs.confirmation': `Deze actie koppelt alle huidige leerkrachten aan alle beschikbare programma's. Je kan deze actie uitvoeren telkens je nieuwe leerkrachten en/of programma's hebt. Let op: als er uitzonderingen zijn zal je die manueel moeten ontkoppelen via het leerkrachtenprofiel.`,
  'link-teachers-programs.success': 'Alle actieve leerkrachten zijn succesvol gekoppeld aan alle programma’s',

  // Multi-screen mode
  'multi-screen-mode.open': 'Open presentator modus',
  'multi-screen-mode.current-slide': 'Huidige slide',
  'multi-screen-mode.no-manual': 'Geen notities voor deze slide.',
  'multi-screen-mode.next-slide': 'Volgende slide',
  'multi-screen-mode.previous-slide': 'Vorige slide',
  'multi-screen-mode.end': 'Je bent op het einde van de les.',
  'multi-screen-mode.progress': '{currentIndex} van {length}',
  'multi-screen-mode.make-main-display': 'Gebruik als hoofdscherm',

  // Smartschool
  'smartschool.popup.lessonsLoading': 'De lessen worden geladen...',
  'smartschool.popup.noClasses': 'Er zijn geen klassen gevonden voor u.  Vraag aan uw schooladmin om klassen te linken.',
  'smartschool.popup.noPrograms': 'Er zijn geen lessen gevonden voor u.  Vraag aan uw schooladmin om lessen te linken of maak zelf een les aan.',
  'smartschool.popup.next': 'Naar stap {step}',
  'smartschool.popup.selectViewMode.exam': 'U koos een toets/examen.  Dit moet altijd in de klasversie worden weergegeven.',
  'smartschool.popup.step1': 'Doelgroep',
  'smartschool.popup.step2': 'Les',
  'smartschool.popup.step3': 'Weergave',
  'smartschool.popup.target.all.description': 'Deze link zal toegankelijk zijn voor alle klassen, er is geen toegangsbeperking.',
  'smartschool.popup.target.all.name': 'Alle klassen',
  'smartschool.popup.target.specific.description': 'Selecteer 1 of meerdere klassen die via deze link toegang mogen hebben tot de les.',
  'smartschool.popup.target.specific.name': 'Specifieke klas(sen)',
  'smartschool.popup.use': 'Gebruiken in Smartschool',

  // Synchroniseer
  'synchronize.checkAll': 'Alle klassen selecteren',
  'synchronize.clearAll': 'Wis alle klassen',
  'synchronize.dailySync': 'Synchroniseer dagelijks',
  'synchronize.lastSynchronized': 'Laatst gesynchronizeerd',
  'synchronize.navLabel': 'Synchronisatie navigatie',
  'synchronize.noneFound': 'Er werden geen klasgroepen gevonden.',
  'synchronize.notSyncedYet': 'Er werd nog niets gesynchroniseerd.',
  "synchronize.percentage": "Aantal behandelde gebruikers, ongeveer {percentage}% van het totaal",
  'synchronize.selected': 'Synchroniseer geselecteerde klassen',
  'synchronize.smartschool': 'Synchroniseer met Smartschool',
  'synchronize.status.warning': "Enkel de laatste {amount} status updates worden getoond.",
  'synchronize.synchronize': 'Synchroniseren',
  'synchronize.teams': 'Synchroniseer met Teams',

  // teams login dialog
  "teams.login.title": "Verbind eerst uw Microsoft-account",
  "teams.login.explanation": "Om deze functionaliteit te gebruiken, moet u eerst uw Microsoft-account koppelen. Nadat u bent ingelogd, wordt u weer naar deze pagina geleid en is de functionaliteit klaar voor gebruik!",

  // teams error
  "teams.error.synchronize.title": "Kan niet synchroniseren met teams.",
};
